<template>
  <div class="logo">
    <img src="@/assets/logo_640.svg" style="width: 50%"/>
    <h1 class="title">Yldman App</h1>
  </div>
</template>

<script setup>
</script>

<style>
  #app {
    font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .logo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: large;
  }


</style>
